import { Injectable } from '@angular/core';
import { AppState } from '@clp/state/app-state';
import { Store } from '@ngxs/store';

export interface IUserInfo {
  upn: string,
  email: string,
  given_name: string,
  family_name: string,
  idp: string,
  idpName: string,
  sub: string
}

@Injectable({providedIn: 'root'})
export class UserInfoService {
  constructor(private store: Store) { }

  public userInfo() {
    const d = this.store.selectSnapshot(AppState.getUserData);
    return d;
  }

  public userInfoTyped() {
    const d = this.store.selectSnapshot(AppState.getUserData);
    return <IUserInfo>d;
  }
}
