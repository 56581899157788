import { Injectable } from '@angular/core';
import { SpaDashboardClient, UserNotificationClient } from '@clp/api';
import { UserInfoService } from '@clp/auth/user-info.svc';
import { ConfigService } from '@clp/config';
import { map, Observable, of, ReplaySubject } from 'rxjs';

export interface IAppTile {
  routerLink?: string;
  href?: string;
  imgSrc: string;
  imgAlt: string;
  imgHeight: string;
  footerText?: string;
}

export interface IAppTileGroup {
  groupName: string;
  tiles: IAppTile[];
}

@Injectable({ providedIn: 'root' })
export class AppTileService {
  constructor(
    private cfgSvc: ConfigService,
    private http: SpaDashboardClient,
    private uiSvc: UserInfoService
  ) {

  }


  public tileGroups$: ReplaySubject<IAppTileGroup[]> = new ReplaySubject<IAppTileGroup[]>();

  public getVisibleGrouped(): Observable<any> {

    const userData = this.uiSvc.userInfo();

    const respo = this.http.getVisibleTiles();

    const groupReview: IAppTileGroup = {
      groupName: 'Review',
      tiles: []
    };

    groupReview.tiles.push({
      routerLink: '../relativity',
      imgSrc: '/assets/img/relativity-stock-md.jpg',
      imgAlt: 'Relativity',
      imgHeight: '120px',
    });

    this.tileGroups$.next([
      groupReview
    ]);

    const groupCorporate: IAppTileGroup = {
      groupName: 'Corporate',
      tiles: []
    };

    const groups = [
      groupReview,
      groupCorporate
    ];

    return respo.pipe(
      map((results: any) => {
        const resultMap = new Map<string,any>(Object.entries(results?.result));
        const athenaTileId = '67ba8460-5449-4f2b-98df-ad2a1e550e77';
        const defaultAthenaEndpoint = 'https://athena.consilio.com';

        if(resultMap.has(athenaTileId)) {
          const endpoint = resultMap.get(athenaTileId)?.spaAddress ?? defaultAthenaEndpoint;
          groupCorporate.tiles.push(
            {
              href: `${endpoint}?challenge=1&idp=${userData.idp}`.toLowerCase(),
              imgSrc: '/assets/img/Logo-Athena-web.png',
              imgAlt: 'Athena',
              imgHeight: '75px'
            });
        }

        const finalGroups = groups.filter(f => f.tiles.length > 0);
        this.tileGroups$.next(finalGroups);
      })
    );
  }

  public getVisibleGroupedTools(): Observable<IAppTileGroup[]> {
    const endpoint = this.cfgSvc.currentConfig.adminAppEndpoint;
    return of([
      {
        groupName: 'Tools',
        tiles: [
          {
            href: endpoint,
            imgSrc: '/assets/img/consilio-logo-sm-blue.png',
            imgAlt: 'ConsilioId Admin App',
            imgHeight: '70px',
            footerText: 'Admin App',
          },
        ],
      },
    ]);
  }
}
